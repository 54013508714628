<template>
  <Layout :pagetitle="'Cliente XYZ'">
    <div class="row align-items-center">
      <div class="col-md-6">
        CUSTOMER ADMIN DETAILS
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'

export default {
  setup () {},
  components: {
    Layout
  }
}
</script>
